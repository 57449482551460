import React from "react";
import { Carousel, Container, Row, Card, Col, Image } from "react-bootstrap";
import ReactDOM from "react-dom/client";
import '../src/css/testimony.css'

class Testimony extends React.Component {
    render() {
        return (
            <div className="testimony" style={{ background:'#8ecae6',paddingTop:'5%' }}>
                <h1 style={{ fontWeight: '',fontSize:'min(4rem,7vw)',color:'#fff',fontWeight:'900'}}>Testimonials</h1>
                <Carousel className='carousel' variant='dark' style={{ paddingBottom: '2%' }}>
                    
                <Carousel.Item>
                        <Container className='carousel-cont'>
                            <Card className='carousel-card' style={{ flexDirection: 'row' ,boxShadow:'0px 0px #ccc', borderRadius:'25px', border:'0px', minHeight:'320px'}}>
                                <center>
                                <Card.Img className='carousel-img' as={Image} src={require('../src/img/Shekiba.jpeg')} roundedCircle />
                                </center>
                                <Card.Body className="carousel-body">
                                    <Card.Title><h3 className='carousel-title' style={{color:'#fb8500',fontWeight:'bold'}}>Shikiba <span>Hazara</span></h3></Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">BA Jurisprudence at the University of Oxford</Card.Subtitle>
                                    <Card.Text className='carousel-text' style={{ textAlign: 'justify', paddingRight: '3%' }}>
                                    I would like to express a heartfelt thank you to my mentors and everyone at UniArk for their support. It is truly a dream to be able to study in such a beautiful and world-renowned university! I would also like to thank my parents. It is through their sacrifice that I am able to pursue further education. I hope that through hard work and perseverance, I can break down more barriers and become a role model for the next generation of Hazara girls.   
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            &nbsp;
                        </Container>
                    </Carousel.Item>

                    <Carousel.Item>
                        <Container className='carousel-cont'>
                            <Card className='carousel-card' style={{ flexDirection: 'row' ,boxShadow:'0px 0px #ccc', borderRadius:'25px', border:'0px', minHeight:'320px'}}>
                                <center>
                                <Card.Img className='carousel-img' as={Image} src={require('../src/img/MortezaEhsani.jpeg')} roundedCircle />
                                </center>
                                <Card.Body className="carousel-body">
                                    <Card.Title><h3 className='carousel-title' style={{color:'#fb8500',fontWeight:'bold'}}>Morteza <span>Ehsani</span></h3></Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">Computer Science at the University of Stanford</Card.Subtitle>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">Mentee from Afghanistan</Card.Subtitle>
                                    <Card.Text className='carousel-text' style={{ textAlign: 'justify', paddingRight: '3%' }}>
                                    I am profoundly grateful to my family, who unconditionally supported and encouraged me to push through despite the odds. This dream wouldn't have come true without the invaluable support of my mentors from UniArk, ElevatEd School, and the Afghan Student Alliance at Columbia University. I am eternally grateful for their guidance. The biggest obstacle to our dreams is often ourselves. Have faith in the process and take that first step towards achieving the dream that keeps you awake at night (for me, it was going to Stanford!). If it feels daunting, don’t hesitate to ask for help. Someone out there is willing to offer you genuine support. You just need to show up!   
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            &nbsp;
                        </Container>
                    </Carousel.Item>

                    <Carousel.Item>
                        <Container className='carousel-cont'>
                            <Card className='carousel-card' style={{ flexDirection: 'row' ,boxShadow:'0px 0px #ccc', borderRadius:'25px', border:'0px', minHeight:'320px'}}>
                                <center>
                                <Card.Img className='carousel-img' as={Image} src={require('../src/img/NematullahAhangosh.jpeg')} roundedCircle />
                                </center>
                                <Card.Body className="carousel-body">
                                    <Card.Title><h3 className='carousel-title' style={{color:'#fb8500',fontWeight:'bold'}}>Nematullah <span>Ahangosh</span></h3></Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">MA Conflict, Security and Development at the University of Sussex</Card.Subtitle>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">Mentee from Afghanistan</Card.Subtitle>
                                    <Card.Text className='carousel-text' style={{ textAlign: 'justify', paddingRight: '3%' }}>
                                    I am grateful to be the first UniArk scholarship recipient. My mentors have been my pillar of support for the past 16 months, providing me with their feedback on numerous university applications. Thanks to every single donor for their gift in facilitating the 'Ark' for my professional development. I am also forever grateful to my friends and families around the world who have contributed towards some of the remaining costs of my education. I hope to make you all proud.   
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            &nbsp;
                        </Container>
                    </Carousel.Item>


                    <Carousel.Item>
                        <Container className='carousel-cont'>
                            <Card className='carousel-card' style={{ textAlign: 'left', flexDirection: 'row',boxShadow:'0px 0px #ccc' , borderRadius:'25px', border:'0px', minHeight:'320px'}}>
                                <center>
                                    <Card.Img className="carousel-img" as={Image} src={require('../src/img/GranazPhoto.jpg')} roundedCircle />
                                </center>
                                
                                <Card.Body className='carousel-body'>
                                    <Card.Title><h3 className="carousel-title" style={{color:'#fb8500',fontWeight:'bold'}}>Granaz <span>Baloch</span></h3></Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">PhD in Gender Studies at the University of Sussex</Card.Subtitle>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">Mentee from Pakistan</Card.Subtitle>
                                    <Card.Text className='carousel-text' style={{ textAlign: 'justify', paddingRight: '3%' }}>
                                    Living 45 kilometers away from the Pakistan and Iran border, I was geographically isolated. However, thanks to UniArk's online mentorship program, distance was no longer a barrier for me. The mentors at UniArk went above and beyond to provide me with the best guidance and support through virtual sessions.
For me, UniArk mentorship is not just an official process; it has been an incredible opportunity to explore and develop my transferable skills. As a result, I felt confident enough to apply to the prestigious Oxford University. UniArk's support extends beyond mentorship; they also focus on uplifting underprivileged communities. Being a woman from Balochistan, Pakistan, where access to quality education is a constant struggle due to limited resources, I deeply appreciate their commitment to helping individuals like me.
Since going through the mentoring process, I have applied to more than three PhD programs. Today, I am thrilled to say that I have received an interview call. I firmly believe that UniArk has the power to transform the life trajectories of numerous vulnerable communities, just like it has for me.I am immensely grateful to be a part of the UniArk family, and I am eager to contribute as a mentor myself, serving this noble cause


                                    </Card.Text>

                                </Card.Body>

                            </Card>
                            &nbsp;
                        </Container>
                    </Carousel.Item>

                    <Carousel.Item>
                        <Container className='carousel-cont'>
                        <Card className='carousel-card' style={{  textAlign: 'left', flexDirection: 'row' ,boxShadow:'0px 0px #ccc', borderRadius:'25px', border:'0px',minHeight:'320px'}}>
                                <center>
                                <Card.Img className='carousel-img' as={Image} src={require('../src/img/Agra.jpg')} roundedCircle />
                                </center>
                                
                                <Card.Body className='carousel-body'>
                                    <Card.Title><h3 className="carousel-title" style={{color:'#fb8500',fontWeight:'bold'}}> Agra Sakti</h3></Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">MPhil in Chemical Engineering at the University of Cambridge</Card.Subtitle>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">Mentee from Indonesia</Card.Subtitle>
                                    <Card.Text className='carousel-text' style={{ textAlign: 'justify', paddingRight: '3%' }}>
                                    Studying at Cambridge is an honour and really exciting. This is not just my success; it belongs to all those who believed in me. I am committed to making the most of this opportunity and leading the path to more students like myself to have a real chance in getting the world class education that Cambridge offers. My thanks go to my mentors at UniArk, for their guidance in supporting me to make a successful application
                                    </Card.Text>

                                </Card.Body>

                            </Card>
                            &nbsp;
                        </Container>
                    </Carousel.Item>

                    <Carousel.Item>
                        <Container className='carousel-cont'>
                            <Card className='carousel-card' style={{ textAlign: 'left', flexDirection: 'row',boxShadow:'0px 0px #ccc' , borderRadius:'25px', border:'0px', minHeight:'320px'}}>
                                <center>
                                    <Card.Img className="carousel-img" as={Image} src={require('../src/img/aish.png')} roundedCircle />
                                </center>
                                
                                <Card.Body className='carousel-body'>
                                    <Card.Title><h3 className="carousel-title" style={{color:'#fb8500',fontWeight:'bold'}}>Aishwarya C. <span>Babu</span></h3></Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">Phd in Advanced Optical Imaging at University College Dublin</Card.Subtitle>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">Winner of Bell Burnell Graduate Scholarship Fund</Card.Subtitle>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">Mentee from India</Card.Subtitle>
                                    <Card.Text className='carousel-text' style={{ textAlign: 'justify', paddingRight: '3%' }}>
                                        I have no words to express how thankful I am to have been chosen as a Bell Burnell Graduate Scholarship Fund awardee. Winning this 
                                        scholarship is a dream come true for me. My career has always been of upmost importance to me and achieving this scholarship validates 
                                        all the hard work and challenges I faced to reach to this point. UniArk also played an important role during the application process. 
                                        Ayngaran, who is a former Bell Burnell Graduate Scholarship Fund awardee, helped me to get in contact with the head of the Bell Burnell 
                                        fund to clarify my queries.

                                    </Card.Text>

                                </Card.Body>

                            </Card>
                            &nbsp;
                        </Container>
                    </Carousel.Item>


                    <Carousel.Item>
                        <Container className='carousel-cont'>
                        <Card className='carousel-card' style={{ textAlign: 'left', flexDirection: 'row',boxShadow:'0px 0px #ccc' , borderRadius:'25px', border:'0px',minHeight:'320px'}}>
                                 <center>
                                <Card.Img className='carousel-img' as={Image} src={require('../src/img/nematullah.webp')} roundedCircle />
                                </center>
                                <Card.Body className="carousel-body">
                                    <Card.Title><h3 className="carousel-title" style={{color:'#fb8500',fontWeight:'bold'}}>Qudratullah <span>Jamil</span></h3></Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted carousel-subtitle">Mentee from Afghanistan</Card.Subtitle>
                                    <Card.Text className='carousel-text' style={{ textAlign: 'justify', paddingRight: '3%' }}>
                                    I cannot sufficiently thank you and the UniArk Team for your incredible support on my application procedure and specifically essay reviews. 
                                    Your sense of support in hard times, your energy and motivational support will sustain for long in my memory. I want to say that you all lightened 
                                    up a candle where darkness prevailed all over my country and sense of hope were barely feasible, while your supports made the candle of hopes be continually flaming. 
                                    Therefore, I full heartedly thank you all for your supports and time you all have given me and supported me to get steps closer to what I dreamed
                                    </Card.Text>

                                </Card.Body>

                            </Card>
                            &nbsp;
                        </Container>
                    </Carousel.Item>
                    
                </Carousel>
            </div>
        );
    }
}

export default Testimony;